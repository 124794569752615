import { default as _91_46_46_46pageSlug_93K2cFVWcfMlMeta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/[...pageSlug].vue?macro=true";
import { default as activatepoCUAsuFWyMeta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/activate.vue?macro=true";
import { default as indexVI0h05stOgMeta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93H4UFPptJE8Meta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newFVRMGfRhaQMeta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlist6C5zoBzfocMeta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/watchlist.vue?macro=true";
import { default as indexxfDvbPXwPpMeta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as infoseYbzSWCot1Meta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/infos.vue?macro=true";
import { default as programjwYaeZlH2JMeta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/program.vue?macro=true";
import { default as indexKgJUq3UVCLMeta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue?macro=true";
import { default as _91showSlug_9378sTbxznlZMeta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue?macro=true";
import { default as _91movieSlug_93GqfdKlDkkuMeta } from "/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug].vue?macro=true";
export default [
  {
    name: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.name ?? "pageSlug___en",
    path: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.path ?? "/en/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_93K2cFVWcfMlMeta || {},
    alias: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.name ?? "pageSlug___de",
    path: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.path ?? "/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_93K2cFVWcfMlMeta || {},
    alias: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.name ?? "pageSlug___es",
    path: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.path ?? "/es/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_93K2cFVWcfMlMeta || {},
    alias: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_93K2cFVWcfMlMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: activatepoCUAsuFWyMeta?.name ?? "account-activate___en",
    path: activatepoCUAsuFWyMeta?.path ?? "/en/account/activate",
    meta: activatepoCUAsuFWyMeta || {},
    alias: activatepoCUAsuFWyMeta?.alias || [],
    redirect: activatepoCUAsuFWyMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: activatepoCUAsuFWyMeta?.name ?? "account-activate___de",
    path: activatepoCUAsuFWyMeta?.path ?? "/konto/aktivieren",
    meta: activatepoCUAsuFWyMeta || {},
    alias: activatepoCUAsuFWyMeta?.alias || [],
    redirect: activatepoCUAsuFWyMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: activatepoCUAsuFWyMeta?.name ?? "account-activate___es",
    path: activatepoCUAsuFWyMeta?.path ?? "/es/cuenta/activar",
    meta: activatepoCUAsuFWyMeta || {},
    alias: activatepoCUAsuFWyMeta?.alias || [],
    redirect: activatepoCUAsuFWyMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: indexVI0h05stOgMeta?.name ?? "account___en",
    path: indexVI0h05stOgMeta?.path ?? "/en/account",
    meta: indexVI0h05stOgMeta || {},
    alias: indexVI0h05stOgMeta?.alias || [],
    redirect: indexVI0h05stOgMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexVI0h05stOgMeta?.name ?? "account___de",
    path: indexVI0h05stOgMeta?.path ?? "/konto",
    meta: indexVI0h05stOgMeta || {},
    alias: indexVI0h05stOgMeta?.alias || [],
    redirect: indexVI0h05stOgMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexVI0h05stOgMeta?.name ?? "account___es",
    path: indexVI0h05stOgMeta?.path ?? "/es/cuenta",
    meta: indexVI0h05stOgMeta || {},
    alias: indexVI0h05stOgMeta?.alias || [],
    redirect: indexVI0h05stOgMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93H4UFPptJE8Meta?.name ?? "account-order-orderNumber-securityKey___en",
    path: _91securityKey_93H4UFPptJE8Meta?.path ?? "/en/account/order/:orderNumber/:securityKey",
    meta: _91securityKey_93H4UFPptJE8Meta || {},
    alias: _91securityKey_93H4UFPptJE8Meta?.alias || [],
    redirect: _91securityKey_93H4UFPptJE8Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93H4UFPptJE8Meta?.name ?? "account-order-orderNumber-securityKey___de",
    path: _91securityKey_93H4UFPptJE8Meta?.path ?? "/konto/bestellung/:orderNumber/:securityKey",
    meta: _91securityKey_93H4UFPptJE8Meta || {},
    alias: _91securityKey_93H4UFPptJE8Meta?.alias || [],
    redirect: _91securityKey_93H4UFPptJE8Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93H4UFPptJE8Meta?.name ?? "account-order-orderNumber-securityKey___es",
    path: _91securityKey_93H4UFPptJE8Meta?.path ?? "/es/cuenta/compra/:orderNumber/:securityKey",
    meta: _91securityKey_93H4UFPptJE8Meta || {},
    alias: _91securityKey_93H4UFPptJE8Meta?.alias || [],
    redirect: _91securityKey_93H4UFPptJE8Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: newFVRMGfRhaQMeta?.name ?? "account-password-new___en",
    path: newFVRMGfRhaQMeta?.path ?? "/en/account/password/new",
    meta: newFVRMGfRhaQMeta || {},
    alias: newFVRMGfRhaQMeta?.alias || [],
    redirect: newFVRMGfRhaQMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: newFVRMGfRhaQMeta?.name ?? "account-password-new___de",
    path: newFVRMGfRhaQMeta?.path ?? "/konto/passwort/neu",
    meta: newFVRMGfRhaQMeta || {},
    alias: newFVRMGfRhaQMeta?.alias || [],
    redirect: newFVRMGfRhaQMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: newFVRMGfRhaQMeta?.name ?? "account-password-new___es",
    path: newFVRMGfRhaQMeta?.path ?? "/es/cuenta/contrasena/nueva",
    meta: newFVRMGfRhaQMeta || {},
    alias: newFVRMGfRhaQMeta?.alias || [],
    redirect: newFVRMGfRhaQMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: watchlist6C5zoBzfocMeta?.name ?? "account-watchlist___en",
    path: watchlist6C5zoBzfocMeta?.path ?? "/en/account/watchlist",
    meta: watchlist6C5zoBzfocMeta || {},
    alias: watchlist6C5zoBzfocMeta?.alias || [],
    redirect: watchlist6C5zoBzfocMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: watchlist6C5zoBzfocMeta?.name ?? "account-watchlist___de",
    path: watchlist6C5zoBzfocMeta?.path ?? "/konto/merkliste",
    meta: watchlist6C5zoBzfocMeta || {},
    alias: watchlist6C5zoBzfocMeta?.alias || [],
    redirect: watchlist6C5zoBzfocMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: watchlist6C5zoBzfocMeta?.name ?? "account-watchlist___es",
    path: watchlist6C5zoBzfocMeta?.path ?? "/es/cuenta/marcadores",
    meta: watchlist6C5zoBzfocMeta || {},
    alias: watchlist6C5zoBzfocMeta?.alias || [],
    redirect: watchlist6C5zoBzfocMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_93GqfdKlDkkuMeta?.path ?? "/en/movie/:movieSlug",
    children: [
  {
    name: indexxfDvbPXwPpMeta?.name ?? "movie-movieSlug___en",
    path: indexxfDvbPXwPpMeta?.path ?? "",
    meta: indexxfDvbPXwPpMeta || {},
    alias: indexxfDvbPXwPpMeta?.alias || [],
    redirect: indexxfDvbPXwPpMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: infoseYbzSWCot1Meta?.name ?? "movie-movieSlug-infos___en",
    path: infoseYbzSWCot1Meta?.path ?? "/en/movie/:movieSlug/infos",
    meta: infoseYbzSWCot1Meta || {},
    alias: infoseYbzSWCot1Meta?.alias || [],
    redirect: infoseYbzSWCot1Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: programjwYaeZlH2JMeta?.name ?? "movie-movieSlug-program___en",
    path: programjwYaeZlH2JMeta?.path ?? "/en/movie/:movieSlug/program",
    meta: programjwYaeZlH2JMeta || {},
    alias: programjwYaeZlH2JMeta?.alias || [],
    redirect: programjwYaeZlH2JMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_9378sTbxznlZMeta?.path ?? "/en/movie/:movieSlug/show/:showSlug",
    children: [
  {
    name: indexKgJUq3UVCLMeta?.name ?? "movie-movieSlug-show-showSlug___en",
    path: indexKgJUq3UVCLMeta?.path ?? "/en/movie/:movieSlug/show/:showSlug",
    meta: indexKgJUq3UVCLMeta || {},
    alias: indexKgJUq3UVCLMeta?.alias || [],
    redirect: indexKgJUq3UVCLMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_9378sTbxznlZMeta?.name ?? undefined,
    meta: _91showSlug_9378sTbxznlZMeta || {},
    alias: _91showSlug_9378sTbxznlZMeta?.alias || [],
    redirect: _91showSlug_9378sTbxznlZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_93GqfdKlDkkuMeta?.name ?? undefined,
    meta: _91movieSlug_93GqfdKlDkkuMeta || {},
    alias: _91movieSlug_93GqfdKlDkkuMeta?.alias || [],
    redirect: _91movieSlug_93GqfdKlDkkuMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_93GqfdKlDkkuMeta?.path ?? "/film/:movieSlug",
    children: [
  {
    name: indexxfDvbPXwPpMeta?.name ?? "movie-movieSlug___de",
    path: indexxfDvbPXwPpMeta?.path ?? "",
    meta: indexxfDvbPXwPpMeta || {},
    alias: indexxfDvbPXwPpMeta?.alias || [],
    redirect: indexxfDvbPXwPpMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: infoseYbzSWCot1Meta?.name ?? "movie-movieSlug-infos___de",
    path: infoseYbzSWCot1Meta?.path ?? "/film/:movieSlug/filminfos",
    meta: infoseYbzSWCot1Meta || {},
    alias: infoseYbzSWCot1Meta?.alias || [],
    redirect: infoseYbzSWCot1Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: programjwYaeZlH2JMeta?.name ?? "movie-movieSlug-program___de",
    path: programjwYaeZlH2JMeta?.path ?? "/film/:movieSlug/spielzeiten",
    meta: programjwYaeZlH2JMeta || {},
    alias: programjwYaeZlH2JMeta?.alias || [],
    redirect: programjwYaeZlH2JMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_9378sTbxznlZMeta?.path ?? "/film/:movieSlug/vorstellung/:showSlug",
    children: [
  {
    name: indexKgJUq3UVCLMeta?.name ?? "movie-movieSlug-show-showSlug___de",
    path: indexKgJUq3UVCLMeta?.path ?? "/film/:movieSlug/vorstellung/:showSlug",
    meta: indexKgJUq3UVCLMeta || {},
    alias: indexKgJUq3UVCLMeta?.alias || [],
    redirect: indexKgJUq3UVCLMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_9378sTbxznlZMeta?.name ?? undefined,
    meta: _91showSlug_9378sTbxznlZMeta || {},
    alias: _91showSlug_9378sTbxznlZMeta?.alias || [],
    redirect: _91showSlug_9378sTbxznlZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_93GqfdKlDkkuMeta?.name ?? undefined,
    meta: _91movieSlug_93GqfdKlDkkuMeta || {},
    alias: _91movieSlug_93GqfdKlDkkuMeta?.alias || [],
    redirect: _91movieSlug_93GqfdKlDkkuMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_93GqfdKlDkkuMeta?.path ?? "/es/movie/:movieSlug()",
    children: [
  {
    name: indexxfDvbPXwPpMeta?.name ?? "movie-movieSlug___es",
    path: indexxfDvbPXwPpMeta?.path ?? "",
    meta: indexxfDvbPXwPpMeta || {},
    alias: indexxfDvbPXwPpMeta?.alias || [],
    redirect: indexxfDvbPXwPpMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: infoseYbzSWCot1Meta?.name ?? "movie-movieSlug-infos___es",
    path: infoseYbzSWCot1Meta?.path ?? "infos",
    meta: infoseYbzSWCot1Meta || {},
    alias: infoseYbzSWCot1Meta?.alias || [],
    redirect: infoseYbzSWCot1Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: programjwYaeZlH2JMeta?.name ?? "movie-movieSlug-program___es",
    path: programjwYaeZlH2JMeta?.path ?? "program",
    meta: programjwYaeZlH2JMeta || {},
    alias: programjwYaeZlH2JMeta?.alias || [],
    redirect: programjwYaeZlH2JMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_9378sTbxznlZMeta?.path ?? "show/:showSlug()",
    children: [
  {
    name: indexKgJUq3UVCLMeta?.name ?? "movie-movieSlug-show-showSlug___es",
    path: indexKgJUq3UVCLMeta?.path ?? "",
    meta: indexKgJUq3UVCLMeta || {},
    alias: indexKgJUq3UVCLMeta?.alias || [],
    redirect: indexKgJUq3UVCLMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_9378sTbxznlZMeta?.name ?? undefined,
    meta: _91showSlug_9378sTbxznlZMeta || {},
    alias: _91showSlug_9378sTbxznlZMeta?.alias || [],
    redirect: _91showSlug_9378sTbxznlZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_93GqfdKlDkkuMeta?.name ?? undefined,
    meta: _91movieSlug_93GqfdKlDkkuMeta || {},
    alias: _91movieSlug_93GqfdKlDkkuMeta?.alias || [],
    redirect: _91movieSlug_93GqfdKlDkkuMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-08-22_17-21-19_5cfb041/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m)
  }
]